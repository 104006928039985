<template>
	<div>
		<div class="bg-gray-800 pb-32">
			<nav-bar />
			<header class="py-10">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<h2 class="text-3xl leading-9 font-bold text-white">
						{{ $route.meta.name | capitalize }}
					</h2>
					<router-link
						v-if="isTrial"
						class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800"
						:to="{ name: 'billing-settings' }"
					>
						Trial Account
					</router-link>
					<router-link
						v-if="isBeta"
						class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800"
						:to="{ name: 'billing-settings' }"
					>
						Beta Account
					</router-link>
				</div>
			</header>
		</div>
		<main class="-mt-32">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<!-- Replace with your content -->
				<div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
					<router-view></router-view>
				</div>
				<!-- /End replace -->
			</div>
		</main>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar'

import { mapState } from 'vuex'
import { GET } from '@/store/actions.type'

export default {
	name: 'Dashboard',
	components: { NavBar },
	computed: {
		...mapState('accounts', {
			account: (state) => state.current
		}),
		isTrial() {
			return this.account.subscription && this.account.subscription.plan === 'trial'
		},
		isBeta() {
			return this.account.subscription && this.account.subscription.plan === 'beta'
		}
	},
	created() {
		this.getAccount()
	},
	metaInfo() {
		return {
			title: 'Dashboard'
		}
	},
	methods: {
		getAccount() {
			this.$store.dispatch(`accounts/${GET}`, this.$route.params.account).then(() => {
				this.$analytics.setUserProperties({ account: this.$route.params.account })
				if (!this.account) {
					this.$router.push('/')
				}
			})
		}
	}
}
</script>

<template>
	<div>
		<div v-if="done" class="bg-gray-50 sm:rounded-lg">
			<div class="px-4 py-5 sm:p-6">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					<!-- <font-awesome-icon class="text-indigo-500" :icon="['fad', 'magic']" /> -->
					Magic Link Sent
				</h3>
				<div class="mt-2 text-sm leading-5 text-gray-500">
					<p>We have emailed a confirmation link to {{ formResponses.email }}, Click the link there, and you will be signed in.</p>
				</div>
				<div class="mt-5">
					<span class="inline-flex rounded-md shadow-sm">
						<a
							href="mailto://hello@storism.io"
							class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
						>
							<!-- <font-awesome-icon class="mr-1" :icon="['fad', 'envelope']" /> -->
							Contact Support
						</a>
					</span>
					<span class="inline-flex rounded-md shadow-sm ml-5">
						<button
							type="button"
							class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
							@click="reset"
						>
							<!-- <font-awesome-icon class="mr-1" :icon="['fad', 'wrench']" /> -->
							Reset
						</button>
					</span>
				</div>
			</div>
		</div>

		<div v-else>
			<div class="flex rounded-md items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
				<div class="max-w-md w-full">
					<div>
						<h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
							Get Your Magic Link to Get Started, no passwords here.
						</h2>
						<p class="text-center mt-5">We will send you a link to get started straight away</p>
					</div>
					<form class="mt-8" @submit.prevent>
						<input type="hidden" name="remember" value="true" />
						<div class="rounded-md shadow-sm">
							<div>
								<input
									v-model.lazy="formResponses.email"
									aria-label="Email address"
									name="email"
									type="email"
									required
									class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
									placeholder="Email address"
								/>
								<p v-if="errors" class="text-red-500 text-xs italic">
									<span v-if="!$v.formResponses.email.required">Email is required</span>
								</p>
							</div>
						</div>

						<div class="mt-6">
							<button
								type="submit"
								class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
								@click="handleGo"
							>
								<span class="absolute left-0 inset-y pl-3">
									<svg
										class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
										fill="currentColor"
										viewBox="0 0 20 20"
									>
										<path
											fill-rule="evenodd"
											d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
											clip-rule="evenodd"
										/>
									</svg>
								</span>

								<font-awesome-icon v-if="isLoading" class="text-white text-3xl" :icon="['fad', 'cog']" spin />
								<span v-else>Send Magic Link</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from '@/helpers/firebaseInit.js'
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
export default {
	name: 'Register',
	data: function() {
		return {
			formResponses: {
				email: ''
			},
			done: false,
			actionCodeSettings: {
				// URL you want to redirect back to. The domain (www.example.com) for this
				// URL must be whitelisted in the Firebase Console.
				// url: 'https://app.storism.io/finishSignUp',
				url: process.env.VUE_APP_ACTIONCODE_URL,
				// This must be true.
				handleCodeInApp: true
			},
			errors: false,
			empty: true,
			formTouched: false,
			uiState: 'submit not clicked'
		}
	},
	validations: {
		formResponses: {
			email: {
				required,
				email
			}
		}
	},
	computed: {
		...mapState('ui', ['isLoading'])
	},
	metaInfo() {
		return {
			title: 'Register'
		}
	},
	mounted() {
		if (window.localStorage.getItem('emailForSignIn')) {
			this.done = true
			this.message = 'You need to click on the magic link we emailed to you'
			this.email = window.localStorage.getItem('emailForSignIn')
		}
	},
	methods: {
		reset() {
			this.done = false
		},
		async handleGo() {
			this.$v.$touch()
			this.handleSubmit()
		},
		async handleSubmit() {
			this.formTouched = !this.$v.formResponses.$anyDirty
			this.errors = this.$v.formResponses.$anyError
			this.uiState = 'submit clicked'
			if (this.errors === false && this.formTouched === false) {
				this.signIn()
			}
		},
		signIn() {
			this.$store.commit('ui/loading', true)
			var sendMagicLink = firebase.functions.httpsCallable('account-sendMagicLink')

			let data = {
				email: this.formResponses.email,
				actionCodeSettings: this.actionCodeSettings
			}

			sendMagicLink(data)
				.then(() => {
					window.localStorage.setItem('emailForSignIn', this.formResponses.email)
					this.done = true
					this.$store.commit('ui/loading', false)
				})
				.catch(() => {
					this.$store.commit('ui/loading', false)
				})
		}
	}
}
</script>

<style></style>

<template>
	<div>
		<nav class="bg-gray-800" @keydown.escape="open = false">
			<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
				<div class="border-b border-gray-700">
					<div class="flex items-center justify-between h-16 px-4 sm:px-0">
						<div class="flex items-center">
							<div class="flex-shrink-0">
								<router-link :to="{ name: 'home' }">
									<img class="auto" src="https://cdn.site.page/file/w_100/app/logo-white.png" alt="" />
								</router-link>
							</div>
							<div class="hidden md:block">
								<div class="ml-10 flex items-baseline">
									<router-link
										:to="{ name: 'dashboard' }"
										active-class="bg-gray-900"
										class="px-3 py-2 rounded-md text-sm font-medium text-white focus:outline-none focus:text-white focus:bg-gray-700"
									>
										Dashboard
									</router-link>
									<router-link
										v-for="n in nav"
										:key="n.id"
										:to="{ name: n.target }"
										active-class="bg-pink-900"
										class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
									>
										{{ n.text }}
									</router-link>
								</div>
							</div>
						</div>
						<div class="hidden md:block">
							<div class="ml-4 flex items-center md:ml-6">
								<button
									class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
								>
									<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
										/>
									</svg>
								</button>
								<div v-on-clickaway="away" class="ml-3 relative" x-data="{ open: false }">
									<div>
										<button
											class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
											@click="open = !open"
										>
											<img v-if="user.photoURL" class="h-8 w-8 rounded-full" :src="user.photoURL" alt="" />
											<span v-else class="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
												<svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
													<path
														d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
													/>
												</svg>
											</span>
										</button>
									</div>
									<div
										v-show="open"
										x-transition:enter="transition ease-out duration-100"
										x-transition:enter-start="transform opacity-0 scale-95"
										x-transition:enter-end="transform opacity-100 scale-100"
										x-transition:leave="transition ease-in duration-75"
										x-transition:leave-start="transform opacity-100 scale-100"
										x-transition:leave-end="transform opacity-0 scale-95"
										class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
									>
										<div class="py-1 rounded-md bg-white shadow-xs">
											<!-- <router-link :to="{ name: 'profile-settings' }" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
												Your Profile
											</router-link> -->
											<router-link
												v-if="isOwner"
												:to="{ name: 'account-settings' }"
												class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
											>
												Account Settings
											</router-link>
											<a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="logout">Sign out</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="-mr-2 flex md:hidden">
							<button
								class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
								@click="toggleOpen"
							>
								<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
									<path
										:class="{ hidden: open, 'inline-flex': !open }"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M4 6h16M4 12h16M4 18h16"
									/>
									<path
										:class="{ hidden: !open, 'inline-flex': open }"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div :class="{ block: open, hidden: !open }" class="border-b border-gray-700 md:hidden">
				<div class="px-2 py-3 sm:px-3">
					<router-link
						:to="{ name: 'dashboard' }"
						exact
						active-class="bg-gray-900"
						class="block px-3 py-2 rounded-md text-base font-medium text-white focus:outline-none focus:text-white focus:bg-gray-700"
					>
						Dashboard
					</router-link>
					<router-link
						v-for="n in nav"
						:key="n.id"
						:to="{ name: n.target }"
						active-class="bg-gray-900"
						class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
					>
						{{ n.text }}
					</router-link>
				</div>
				<div class="pt-4 pb-3 border-t border-gray-700">
					<div class="flex items-center px-5">
						<div class="flex-shrink-0">
							<img v-if="user.photoURL" class="h-10 w-10 rounded-full" :src="user.photoURL" alt="" />
						</div>
						<div class="ml-3">
							<div class="text-base font-medium leading-none text-white">{{ user.displayName }}</div>
							<div class="mt-1 text-sm font-medium leading-none text-gray-400">{{ user.email }}</div>
						</div>
					</div>
					<div class="mt-3 px-2">
						<router-link
							v-if="isOwner"
							:to="{ name: 'account-settings' }"
							class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
						>
							Account Settings
						</router-link>
						<a
							href="#"
							class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
							@click.prevent="logout"
						>
							Sign out
						</a>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
	name: 'Navbar',
	mixins: [clickaway],
	data() {
		return {
			open: false
		}
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
			account: (state) => state.accounts.current
		}),
		...mapGetters('accounts', ['isOwner']),
		nav: function() {
			return this.$route.matched[0].meta.nav
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('auth/logout').then(() => {
				this.$router.push('/')
			})
		},
		settings() {},
		away: function() {
			//	this.open = false
		},
		toggleOpen() {
			this.open = !this.open
		}
	}
}
</script>

<style>
.active.nav-link.navbar-link {
	border-bottom: 2px solid #fd0084;
	/* font-weight: bold; */
}
</style>

<template>
	<div>
		<div class="min-h-screen bg-gray-800">
			<div class="relative max-w-screen-xl mx-auto pt-5 px-4 py-12 sm:px-6 lg:px-8 lg:py-16 ">
				<div class="md:ml-auto md:pl-10">
					<img src="/logo-white.png" class="h-16" alt="" />
					<h2 class="mt-2 text-white text-xl leading-9 font-bold tracking-tight sm:text-3xl sm:leading-10">
						What data do you have, and what is it doing?
					</h2>
					<div class="mt-3">
						<span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800">
							v1.0
						</span>
						<button
							v-if="userId"
							class="ml-5 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-gray-600 text-gray-400"
							@click="logOut"
						>
							Log Out
						</button>
					</div>
					<div class="mt-8">
						<div class="rounded-md">
							<register v-if="!userId" />
							<div v-else>
								<router-view />
							</div>
						</div>
					</div>
					<div class="bg-gray-600 overflow-hidden rounded-lg mt-5 shadow-md">
						<div class="px-4 py-5 sm:p-6">
							<div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
								<div class="lg:grid lg:grid-cols-3 lg:gap-8 ">
									<div>
										<div class="flex items-center justify-center">
											<img class="object-cover h-20 w-full rounded-md" src="/img/connections.png" alt="" />
										</div>
										<div class="mt-5">
											<h5 class="text-lg leading-6 font-medium text-gray-300">Connections</h5>
											<p class="mt-2 text-base leading-6 text-gray-500">
												Get a concise view of your Data Landscape, what data is being created and where it is going
											</p>
										</div>
									</div>
									<div class="mt-10 lg:mt-0">
										<div class="flex items-center justify-center">
											<img class="object-cover h-20 w-full rounded-md" src="/img/risk.png" alt="" />
										</div>
										<div class="mt-5">
											<h5 class="text-lg leading-6 font-medium text-gray-300">Reduce Risk</h5>
											<p class="mt-2 text-base leading-6 text-gray-500">
												If you want to change your Data Structure, or see which GDPR fields are being used you can see this straight from
												your Dashboard
											</p>
										</div>
									</div>
									<div class="mt-10 lg:mt-0">
										<div class="flex items-center justify-center">
											<img class="object-cover h-20 w-full rounded-md" src="/img/op.png" alt="" />
										</div>
										<div class="mt-5">
											<h5 class="text-lg leading-6 font-medium text-gray-300">New Opportunities</h5>
											<p class="mt-2 text-base leading-6 text-gray-500">
												Data Migration, integrating a new system or thinking of novel uses of your data become much easier once you can see
												what is going on.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Register from './Register'
export default {
	name: 'Home',
	components: {
		Register
	},
	data: function() {
		return {
			message: ''
		}
	},
	computed: {
		...mapState({
			userId: (state) => state.auth.user.id,
			accounts: (state) => state.auth.user.accounts
		})
	},
	metaInfo() {
		return {
			title: 'Home'
		}
	},
	mounted() {
		if (this.$route.name === 'home' && this.userId) {
			if (!this.accounts) {
				this.$router.replace({ name: 'account.create' })
			} else {
				this.$router.replace({ name: 'account' })
			}
		}
	},
	methods: {
		logOut() {
			this.$store.dispatch('auth/logout').then(() => {
				this.$router.push('/')
			})
		}
	}
}
</script>
